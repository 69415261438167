'use client'

import { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import type { PropsWithChildren, ReactElement } from 'react'

const QueryContextProvider = ({ children }: PropsWithChildren): ReactElement => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
            // globally default to 20 seconds
            staleTime: 1000 * 20,
          },
        },
      })
  )

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export default QueryContextProvider
